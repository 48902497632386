import React from 'react';
//import { Link } from 'gatsby';
import Link from 'utils/localized-link'
import { FormattedMessage } from 'react-intl';

import Button from '@youship/components/objects/button';
import Img from 'components/image';

import './styles.scss';

const headerImage = 'about/detectnet-ssd.jpg';

const ExpertiseHeader = () => (
  <div className="expertise-header">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-12 col-md-7">
          <div className="expertise-header__title-container">
            <div className="expertise-header__title-wrapper">
              <h1 className="h1--display expertise-header__title">
                <FormattedMessage id="expertise.header.title" />
              </h1>
              <div className="expertise-header__title-filler" />
            </div>
          </div>
          <div className="expertise-header__description">
            <FormattedMessage id="expertise.header.subtitle" />
          </div>
          <div className="expertise-header__description-sub">
            <FormattedMessage id="expertise.banner.title" />
            &nbsp;&nbsp;
            <Button
              context="primary"
              noBackground
              noPadding
              noShadow
              small
              linkComponent={Link}
              linkProps={{ to: '/contacts' }}
            >
              <FormattedMessage id="expertise.banner.buttons.contacts" />
            </Button>
          </div>
        </div>
      </div>
      <div className="expertise-header__image-wrapper">
        <div className="expertise-header__background-element" />
        <Img
          src={headerImage}
          alt="Header 1"
          classNames="expertise-header__image"
        />
      </div>
    </div>
  </div>
);

export default ExpertiseHeader;
