import React from 'react';

import Link from 'utils/localized-link';
import ExpertiseHeader from 'components/sections/expertise-header';
import Awards from 'components/sections/awards';

// Hidden for now:
// import AboutMedia from 'components/sections/about-media';
// import AboutMediaKit from 'components/sections/about-media-kit';
import Banner from 'components/sections/banner';
import Benefits from 'components/sections/benefits';
import Layout from 'components/layout';
import SEO from 'components/seo';

//import ObjectiveIcon from 'images/icons/objective.inline.svg';
//import EyeIcon from 'images/icons/eye.inline.svg';
//import PeopleIcon from 'images/icons/people.inline.svg';
import CreditCardIcon from 'images/icons/credit-card.inline.svg';
import InfrastructureIcon from 'images/icons/infrastructure.inline.svg';
import ListIcon from 'images/icons/list.inline.svg';
import AvatarIcon from 'images/icons/avatar.inline.svg';

const bannerImage = 'home/robot-banner-bottom2.jpg';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'expertise.banner.buttons.contacts'
  }
];

const BENEFITS = [
    {
      description: 'expertise.benefits.b1.title',
      icon: CreditCardIcon
    },
    {
      description: 'expertise.benefits.b2.title',
      icon: ListIcon
    },
    {
      description: 'expertise.benefits.b3.title',
      icon: InfrastructureIcon
    },
    {
        description: 'expertise.benefits.b4.title',
        icon: AvatarIcon
    }
  ];


const ExpertisePage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="expertise.title" />
    <ExpertiseHeader />
    <Benefits
      alignTextLeft
      benefits={BENEFITS}
      description="expertise.benefits.description"
      title="expertise.benefits.title"
    />
    <Awards/>
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      title="expertise.banner.title"
    />
  </Layout>
);

export default ExpertisePage;
